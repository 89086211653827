import types from './types';

const {
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
  SET_DETAILS, SORT,
  SHOW_FLOORPLAN, HIDE_FLOORPLAN,
  SEND_CONTACT_REQUEST, SEND_CONTACT_REQUEST_SUCCESS, SEND_CONTACT_REQUEST_FAIL,
  RESET_CONTACT_REQUEST,
} = types;

const initialState = {
  properties: [],
  detailsId: null,
  datePickerOpen: false,
  loaded: false,
  loading: false,
  searchLogId: '',
  order: null,
  isContactRequestSent: false,
  error: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      return {
        ...state,
        loading: true,
        error: null,
        isContactRequestSent: false,
      };
    }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        properties: action.result,
        searchLogId: action.headers.searchlogid,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        properties: [],
        error: action.error,
      };
    case SEND_CONTACT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SEND_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        isContactRequestSent: true,
      };
    case SEND_CONTACT_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case RESET_CONTACT_REQUEST:
      return {
        ...state,
        isContactRequestSent: false,
      };
    case SET_DETAILS:
      return {
        ...state,
        detailsId: state.detailsId === action.id ? null : action.id,
      };
    case SHOW_FLOORPLAN:
      return {
        ...state,
        floorPlanImageUrl: action.imageUrl,
        showFloorPlan: true,
      };
    case HIDE_FLOORPLAN:
      return {
        ...state,
        floorPlanImageUrl: null,
        showFloorPlan: false,
      };
    case SORT: {
      const { properties } = state;
      const { order } = action;
      // The order comes in the form of 'attribute DESC', 'attribute ASC', or simply 'attribute' (asc)
      const orderKeys = order.split(' ');

      properties.sort((room, anotherRoom) => {
        if (orderKeys.length > 1 && orderKeys[1] === 'DESC') {
          // Descending order
          return room[orderKeys[0]] < anotherRoom[orderKeys[0]] ? 1 : -1;
        }
        // Ascending order
        return room[orderKeys[0]] > anotherRoom[orderKeys[0]] ? 1 : -1;
      });

      return {
        ...state,
        order,
        properties,
      };
    }
    default:
      return state;
  }
}
